.query-builder .group {
    background: #ebf5fa;
    border: 1px solid #53a9d3;
}

.query-builder .group svg.MuiSvgIcon-root {
    fill: #53a9d3
}


.query-builder .group--actions .MuiIconButton-colorSecondary svg,
.query-builder .rule--header svg.MuiSvgIcon-root {
    fill: #cf5757
}