#main ::-webkit-scrollbar,
#main ::-webkit-scrollbar-thumb {
    width: 6px;
    height: 6px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 1px solid transparent;
}

#main ::-webkit-scrollbar-thumb {
    background-color: #b2b2b2;
}