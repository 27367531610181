#style-4::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar {
    width: 0px;
    background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar-thumb {
    background-color: #000000;
    border: 2px solid #555555;
}